<template>
    <div class="view-home">
        <div :style="{ 'backgroundImage': `url(${require('../assets/images/view/home/home.png')})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'4rem'}"></div>
         <!-- <img class="bannerImg" src="../assets/images/view/home/home.png" alt=""> -->
         <div class="paragraphs">
             <div class="introduction">
                <div class="title">{{pages.introduction.title}}</div>  
                <div class="contents">
                    <div class="left image"  :style="{ 'backgroundImage': `url(${pages.introduction.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'100%'}"></div>
                    <div class="right content" style="text-indent:2em">{{pages.introduction.content}}</div>
                </div> 
             </div>
             <div class="cases">
                 <div class="title">{{pages.cases.title}}</div>
                 <div class="more" @click="$router.push('/viewCases')">查看更多</div>
                 <div class="contents">
                     <div class="right">
                         <div class="image" :style="{ 'backgroundImage': `url(${pages.cases.subContent.left.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'100%'}"></div>
                          <div class="subContent">
                              <p class="subTitle">{{pages.cases.subContent.left.title}}</p>
                              <p class="content" v-for="(item,i) of pages.cases.subContent.left.contents" :key="i">{{item}}</p>
                          </div>
                     </div>
                     <div class="left">
                         <div class="top">
                             <div  v-for="(item,i) of pages.cases.subContent.right.contents" :key="i">
                                 <p class="title" style="font-size:0.16rem;font-weight:bold">{{item.title}}</p>
                                 <div class="images" :style="{ 'backgroundImage': `url(${item.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover',}"></div>
                                 <!-- <img :src="item.url" alt=""> -->
                                 </div>
                         </div>
                         <div class="discription">{{pages.cases.subContent.right.discription}}
                             <span style="color:red">{{pages.cases.subContent.right.disContent}}</span>
                         </div>
                     </div>
                 </div>
             </div>
             <div class="resources">
                 <div  v-for="(item,index) of pages.resources" :key="index">
                      <div class="title">{{item.title}}</div>
                      <div class="contents">
                           <div class="left">
                        <div :style="{ 'backgroundImage': `url(${item.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','width':'30%','height':'40%'}"></div>
                      </div>
                    <div class="right">
                        <p class="content" v-for="(it,i) of item.contents" :key="i">{{it}}</p>
                    </div>  
                </div>
                </div>
             </div>
             <div class="services">
                 <div class="title">{{pages.services.title}}</div>
                 <div class="contents">
                     <div v-for="(item,i) of pages.services.contents" :key="i">
                         <!-- <div :style="{ 'backgroundImage': `url(${item.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'40%'}"></div> -->
                     <div class="image">
                          <img :src="item.url" alt="">
                     </div>
                       <p class="content">{{item.content}}</p>
                     </div>
                 </div>
             </div>
            <div class="partner">
                <p class="panoramaTitle title">合作伙伴</p>
                <p class="partnerTitle">我们将与众多同行者一起共建人工智能产业生态</p>
                <div class="partnerList">
                    <img v-for="(item, index) in imglist" :key="index"  :src="item" style="margin: 0 0.5rem;">
                </div>
        </div>  
         </div>
    </div>
</template>
<script>
/* eslint-disable */
export default {
    data() {
        return {
             imglist:[
                require('@/assets/images/partner/1.png'),require('@/assets/images/partner/2.png'),require('@/assets/images/partner/3.png'),
                require('@/assets/images/partner/4.png'),require('@/assets/images/partner/5.png'),require('@/assets/images/partner/6.png'),
                require('@/assets/images/partner/7.png'),require('@/assets/images/partner/8.png'),require('@/assets/images/partner/9.png'),
                require('@/assets/images/partner/10.png'),require('@/assets/images/partner/11.png'),require('@/assets/images/partner/12.png'),
                require('@/assets/images/partner/13.png'),require('@/assets/images/partner/14.png'),require('@/assets/images/partner/15.png'),
                require('@/assets/images/partner/16.png'),require('@/assets/images/partner/17.png'),require('@/assets/images/partner/18.png'),
                require('@/assets/images/partner/19.png'),require('@/assets/images/partner/20.png'),require('@/assets/images/partner/21.png'),
            ],
            pages: {
                introduction:{
                    title:'公司简介',
                    content:'南京中科逆熵科技有限公司是一家创新型高科技企业，主要进行智能计算平台研发、运营及生态拓展工作。公司核心技术团队毕业于中国科学技术大学，在软硬件协同设计、高能效系统设计、智能计算系统等领域有拥有丰富的技术储备，公司已与北京中科寒武纪科技有限公司建立了长期合作关系，寒武纪将在产品供应、技术支持等领域给予本公司全面支持,公司与中国科学技术大学苏州高等研究院建立了紧密合作关系,同时组建了由国内外知名科学家、企业家构成的顾问委员会。',
                    url:require('@/assets/images/view/home/p1_1.png')
                },
                cases:{
                     title:'项目案例展示',
                     subContent:{
                         left:{
                             title:'面膜检测',
                             contents:['检测结果:漏检≤0.3%|过杀≤1%','缺陷类型:黑点、毛发、破损......'],
                             url:require('@/assets/images/view/home/p2_1.png')
                         },
                         right:{
                             discription:'瑕疵类型:',
                             disContent:'破损',
                             contents:[
                                 {title:"原图",url:require('@/assets/images/view/home/p2_2.png')},
                                 {title:"检测结果",url:require('@/assets/images/view/home/p2_3.png')}
                                 ]
                         }
                     }
                },
                resources:[
                        {title:'资料下载中心',contents:['客户使用说明书','产品资料工具下载库'],url:require('@/assets/images/view/home/p3_1.png')},
                        {title:'工具',contents:['模拟开发工具(web训练机窗口)','硬件工具打光方案软件窗口'],url:require('@/assets/images/view/home/p4_1.png')}
                    ],
                services:{
                        title:'产品服务',
                        contents:[
                                {content:'软件(SDK)',url:require('@/assets/images/view/home/p5_1.png')},
                                {content:'软件方案(应用程序开发)',url:require('@/assets/images/view/home/p5_2.png')},
                                {content:'整体解决方案',url:require('@/assets/images/view/home/p5_3.png')},
                                {content:'硬件产品',url:require('@/assets/images/view/home/p5_4.png')}
                            ]
                        }
                },
        }
    },
}
</script>
<style lang="scss" scoped>
    .view-home{
          @keyframes partnerAnima
    {
        0%   {right: 0}
        50%  {right: 30rem}
        100%  {right: 60rem}
    }
    .partnerList {
        position: relative;
        animation: partnerAnima 100s linear 1 alternate;
    }
      .partner {
        overflow: hidden;
        width: 80%;
        margin: 0 auto;
        text-align: center;
        .partnerList {
            height: 1.2rem;
            width: 61rem;
            img {
                height: 1.2rem;
                float: left;
            }
        }
        .partnerTitle {
            color: #999999;
            font-size: 0.16rem;
            margin: 0.2rem 0;
        }
        .title{
            margin-bottom: 0.2rem!important;
        }
    }
        .paragraphs{
            >div{
                width: 70%;
                margin:0.5rem auto 0
                
            }
            .title{
                font-size: 0.3rem;
                line-height: 0.35rem;
                text-align: center;
                font-weight: 400;
                color:#333333
            }
            .contents{
                display: flex;
                height: 2.5rem;
                margin-top: 0.5rem;
                justify-content: space-between;
                align-items: center;
                .image{
                    width:35%;
                    height: 100%;
                }
              .left{
                  width: 35%;
              }
                .right{
                    width: 55%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                }
                .content{
                    font-size: 0.18rem;
                    font-weight: 400;
                    line-height: 0.35rem;
                }
            }
            .subTitle{
                  font-size: 0.2rem;
                  font-weight: bold;
                  line-height: 0.35rem;
                  margin-bottom: 0.2rem;
            }
            .subContent {
                // display: flex;
                // flex-direction: column;
                // justify-content: space-around;
                padding: 0.1rem 0;
                .content{
                  margin-bottom: 0.2rem;  
                  font-family: Source Han Sans CN;
                  color: #666666;
                }
            }
            .cases{
                .more{
                    margin-left: 85%;
                    margin-top: 0.2rem;
                    font-size: 0.18rem;
                    color: #0061E3 !important;
                    font-weight: 500;
                    cursor:pointer
                }
                .right{
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }
                .contents{
                    height: 2rem;
                }
                .subContent{
                    margin-right:25%;
                }
                .top {
                    display: flex;
                    font-size:0.16rem;
                    >div{
                        width: 48%;
                         height: 1.5rem;
                    }
                    .images{
                        display: block;
                        width: 90%;
                        height: 1.2rem;
                        margin:0.1rem auto 0;
                    }
                }
                .discription{
                    margin-top: 0.3rem;
                    text-align: center;
                    font-size: 0.16rem;
                }
            }
        }
        .resources{
            .title{
                margin-bottom: 0;
            }
            .contents{
                margin-top: 0;
                height: 3rem;
                .left{
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    >div{
                        margin-left: 60%;
                    }
                }
                .right{
                    display: block;
                    height: auto;
                    // padding: 0.5rem 0;
                    // box-sizing: border-box;
                    p{
                        margin-bottom: 0.3rem;
                    }
                }
            }
            
        }
        .services{
            .contents{
                .image{
                    height: 1.5rem; 
                    width: 100%;
                }
                img{
                    height: 90%;
                    display: flex;
                    margin:auto;
                }
                .content{
                    text-align: center;
                    margin-top: 0.2rem;
                }
            }
        }
    }
</style>